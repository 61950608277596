body {
  margin: 0;
  padding:0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  touch-action: manipulation;
  user-select: none;
}

.pixel-grid {
  display: grid;
  justify-content: center;
  align-content: center;
  flex-grow: 1;
  grid-template-columns: repeat(28, 0.7em);
  grid-template-rows: repeat(28, 0.7em);
  margin: 2em;
  margin-right: 2em;
  grid-gap: 0;
}

.pixel {
  cursor: crosshair;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 2em;
  color: white;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: transform 250ms linear;
}

.clicked {
  background-color: black !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}